import React from 'react';
import Header from './components/Header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ULKForm from './components/ULKForm';
import Packages from './components/Packages';
import loader from './assets/img/spinner.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      ulk: '',
      loading: false,
      error: false,
      errorMsg: '',
      errorCode: '',
      data: [],
    };
  }

  componentDidMount() {
    let params = this.getURLParam('ulk');
    if (typeof params.result !== 'undefined' && params.result) {
      this.handleChange(params.result);
    }
  }

  getURLParam(param) {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      result: searchParams.get(param) || '',
    };
  }

  getData(ulk) {
    var MyOptions = { method: 'GET' };

    fetch(`https://api.devmanextensions.com/v1/client-releases/${ulk}`, MyOptions)
      .then((response) => {
        if (!response.ok) {
          this.setState({ error: true, loading: false, errorCode: response.status, data: [] });
        }

        return response.json();
      })
      .then((json) => {
        const error = this.state.error;

        if (error) {
          this.setState({ errorMsg: json.msg });
        } else {
          const arrayResult = json.license.supports.map((arr) => ({
            supportId: arr.id,
            isFree: arr.isFree,
            renewedAt: new Date(arr.renewedAt),
            downloadsUntil: new Date(arr.downloadsUntil),
            supportUntil: new Date(arr.validUntil),
            platform: { ...arr.product.platform },
            extension: { ...arr.product.extension },
            releases: [...arr.product.releases],
          }));

          this.setState({ loading: false, error: false, data: arrayResult });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(ulk) {
    ulk = ulk.trim();

    if (ulk.length === 14) {
      this.setState({ loading: true });
      this.getData(ulk);
    } else {
      this.setState({ loading: false, error: false, data: [] });
    }

    this.setState({ ulk: ulk });
  }

  render() {
    const ulk = this.state.ulk;
    const data = this.state.data;
    const loading = this.state.loading;
    const error = this.state.error;
    const errorCode = this.state.errorCode;
    const errorMsg = this.state.errorMsg;

    return (
      <div className="App">
        <Header />
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <ULKForm ulk={ulk} onChange={this.handleChange} />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              {loading ? (
                <p className="text-center">
                  <img src={loader} alt="Loading..." className="data-loader" />
                  <br />
                  <small className="text-muted">...loading your packages...</small>
                </p>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            {error ? (
              <Col md="auto">
                <p className="text-center">
                  <small className="badge badge-danger">{errorCode}</small>
                  <br />
                  {errorMsg}
                </p>
              </Col>
            ) : (
              <Packages data={data} ulk={ulk} />
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

import React from 'react';
import Form from 'react-bootstrap/Form';

export default class ULKForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  render() {
    const ulk = this.props.ulk;

    return (
      <Form className="ulk-form">
        <Form.Group controlId="ulkForm">
          <Form.Label className="ulk-label">ULK</Form.Label>
          <Form.Control
            type="text"
            value={ulk}
            onChange={this.handleChange}
            className="ulk-input"
          />
          <small className="form-text text-muted text-left">Enter your Unique License Key.</small>
        </Form.Group>
      </Form>
    );
  }
}

import React from 'react';
import PackageTable from './PackageTable';
import Col from 'react-bootstrap/Col';

export default class Packages extends React.Component {
  renderTable(product, key) {
    const ulk = this.props.ulk;

    return (
      <Col xs md="8">
        <PackageTable product={product} ulk={ulk} key={`package-table-${key}`} />
      </Col>
    );
  }

  render() {
    const data = this.props.data;

    if (data.length) {
      return data.map((d, i) => {
        return this.renderTable(d, i);
      });
    } else {
      return null;
    }
  }
}

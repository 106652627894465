import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export default class PackageTable extends React.Component {
  renderRows() {
    const product = this.props.product;
    const ulk = this.props.ulk;

    if (product.isFree) {
      return (
        <tr>
          <th></th>
          <td colSpan="5" className="text-center">
            <small className="text-muted">
              You have a <u>free</u> version of this product.
            </small>
            <br />
            <a
              href={`https://devmanextensions.com/invoices/opencart/new_invoice?type=license&extension=${product.extension.slug}&platform=${product.platform.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Update to PRO.
            </a>
          </td>
        </tr>
      );
    } else {
      return product.releases.map((release, index) => {
        const releasedAt = new Date(release.releasedAt);

        if (index < 10) {
          return (
            <tr key={index} className={!release.supported ? 'table-danger' : ''}>
              <th>{index + 1}</th>
              <td className="text-center">{release.version}</td>
              <td className="text-right">{releasedAt.toDateString()}</td>
              <td>
                {release.supported ? (
                  <FontAwesomeIcon icon={faThumbsUp} className="text-success" />
                ) : (
                  <FontAwesomeIcon icon={faThumbsDown} className="text-danger" />
                )}
              </td>
              <td>
                {release.supported ? (
                  <form
                    action={`https://api.devmanextensions.com/v1/${product.platform.slug}/${product.extension.slug}/releases/${release.version}/get-package`}
                    method="post"
                  >
                    <input type="hidden" name="license" value={ulk} />
                    <button type="submit" className="btn btn-sm btn-dark">
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </form>
                ) : (
                  this.renderExtendSupport(false, product.supportId)
                )}
              </td>
            </tr>
          );
        } else {
          return '';
        }
      });
    }
  }

  renderInfo() {
    const product = this.props.product;
    const now = Date.now();
    const stillSupport = product.supportUntil >= now;
    const stillDownload = product.downloadsUntil >= now;

    return (
      <div className="package-table-info">
        <p>
          <strong>Support Renewed At:</strong>{' '}
          <span className="badge badge-pill badge-dark">{product.renewedAt.toDateString()}</span>
          <br />
          <strong>Downloads Until:</strong>{' '}
          <span
            className={
              stillDownload ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'
            }
          >
            {product.downloadsUntil.toDateString()}
          </span>{' '}
          {this.renderExtendSupport(stillDownload, product.supportId)}
          <br />
          <strong>Support Until:</strong>{' '}
          <span
            className={
              stillSupport ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'
            }
          >
            {product.supportUntil.toDateString()}
          </span>{' '}
          {this.renderExtendSupport(stillSupport, product.supportId)}
        </p>
        <p className="text-right">
          <small>
            * Visit our{' '}
            <a
              href="https://devmanextensions.com/payment-and-refunds"
              target="_blank"
              rel="noopener noreferrer"
            >
              License Terms
            </a>{' '}
            if you have some questions.
          </small>
        </p>
      </div>
    );
  }

  renderExtendSupport(support, id) {
    if (!support) {
      return (
        <span>
          <a
            href={`https://devmanextensions.com/invoices/cs-cart/new_invoice?type=renew_license&support_id=${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Extend your support
          </a>
        </span>
      );
    } else {
      return;
    }
  }

  render() {
    const product = this.props.product;

    return (
      <div className="package-table-wrapper">
        <h3>
          {product.extension.name} for {product.platform.name}
        </h3>
        {this.renderInfo()}
        <Table size="sm" className="packages-table" borderless hover>
          <thead>
            <tr>
              <th>#</th>
              <th className="text-center">Version</th>
              <th className="text-right">Released At</th>
              <th>Supported</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </Table>
      </div>
    );
  }
}

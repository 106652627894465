import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavbarLogo from '../assets/img/navbar-logo.svg';

const headerStyle = {
  backgroundColor: '#040059',
  marginBottom: '25px',
};

export default class Header extends React.Component {
  render() {
    return (
      <header style={headerStyle}>
        <Navbar expand="lg" variant="dark">
          <Navbar.Brand href="https://devmanextensions.com">
            <img
              alt=""
              src={NavbarLogo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Devman Download Center
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
      </header>
    );
  }
}
